<svelte:options accessors={true} />

<script>
  import { onDestroy, onMount } from "svelte";
  import { _ } from "svelte-i18n";

  /** 読込中に表示するメッセージ（HTMLタグ可） @type {string} */
  export let message;

  /** @type {HTMLDivElement} */
  let contentsElement;
  /** @type {HTMLElement} */
  let activeElement;

  onMount(() => {
    activeElement = /** @type {HTMLElement} */ (document.activeElement);
    contentsElement?.focus?.();
  });

  onDestroy(() => {
    if (activeElement && activeElement.checkVisibility?.() !== false) {
      activeElement.focus?.();
    }
  });
</script>

<svelte:document
  on:keydown|capture={(/** @type {KeyboardEvent} */ event) => {
    event.preventDefault();
    return false;
  }}
/>

<div class="overlay" />
<div bind:this={contentsElement} class="contents" tabindex="-1">
  <div class="spinner" />
  <p class="message">
    {#if message}
      {@html message}
    {:else}
      {@html $_("components.LoadingProgress.nowLoading")}
    {/if}
  </p>
</div>

<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: var(--z-index-loading);
  }

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 35px;
    position: fixed;
    top: 50%;
    width: 100%;
    padding: 60px 0 70px;
    background: #2a2a2a;
    color: #fff;
    transform: translateY(-50%);
    z-index: calc(var(--z-index-loading) + 1);
    opacity: 0;
    animation: fadeIn-keyframes 150ms ease-in forwards;
    will-change: opacity;
  }

  .message {
    font-size: 1.1em;
    line-height: 1.3;
    text-align: center;
  }

  @keyframes fadeIn-keyframes {
    100% {
      opacity: 1;
    }
  }

  .spinner,
  .spinner:before,
  .spinner:after {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    animation: load-keyframes 1.8s infinite ease-in-out;
    animation-fill-mode: both;
    -webkit-animation: load-keyframes 1.8s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    will-change: box-shadow;
  }

  .spinner {
    position: relative;
    margin-bottom: 40px;
    color: #fff;
    font-size: 10px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
    -webkit-transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    will-change: box-shadow;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -3.5em;
      animation-delay: -0.32s;
      -webkit-animation-delay: -0.32s;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 3.5em;
    }
  }

  @keyframes load-keyframes {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }

  @-webkit-keyframes load-keyframes {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
</style>

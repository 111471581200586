<script>
  import { escape } from "html-escaper";
  import { _, locale } from "svelte-i18n";

  import { formatDate } from "~/libs/dateUtils";

  /** @type {import("~/libs/commonTypes").ExtraEvent} */
  export let trouble = null;
  /** @type {import("~/libs/commonTypes").ReturnInfo} */
  export let returnInfo = null;
  /** @type {import("~/libs/commonTypes").AgreementOfDropPlace}*/
  export let agreementOfDropPlace = null;

  const messageKey = trouble
    ? `messages.trouble.${trouble.extraEventType}`
    : returnInfo
      ? `messages.return.${returnInfo.returnReason}`
      : "messages.agreeForDropPlace";
  const time = trouble
    ? trouble.time
    : returnInfo
      ? returnInfo.time
      : agreementOfDropPlace.agreedAt;

  /** @type {string} 指定された置き配場所 */
  $: designatedDropPlace = Number.isInteger(
    Number(agreementOfDropPlace?.designatedDropPlace),
  )
    ? $_(
        `messages.packageDropPlace.${agreementOfDropPlace?.designatedDropPlace}`,
      )
    : $_("config.nameSuffix") === "Ja"
      ? `${agreementOfDropPlace?.designatedDropPlace}`
      : `"${agreementOfDropPlace?.designatedDropPlace}"`;
  /** @type {string} 置き配場所の事前調整方法*/
  $: contactMethod = Number.isInteger(
    Number(agreementOfDropPlace?.contactMethod),
  )
    ? $_(`messages.contactMethod.${agreementOfDropPlace?.contactMethod}`)
    : $_("config.nameSuffix") === "Ja"
      ? `${agreementOfDropPlace?.contactMethod}`
      : `"${agreementOfDropPlace?.contactMethod}"`;
</script>

<div class="trouble">
  <div class="troubleLine1">
    <div class="troubleTime">
      <span
        class="formatedDate {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
        >{formatDate(
          time,
          $_("config.defaultDateFormat"),
          $locale,
        )}{#if $_("config.nameSuffix") === "En"},{/if}</span
      ><span
        class="formatedTime {$_('config.nameSuffix') === 'Ja' ? 'ja' : 'en'}"
        >{formatDate(time, $_("config.defaultTimeFormat"), $locale)}</span
      >
    </div>
  </div>
  <div class="troubleLine2">
    <div class="troubleMessage">
      {#if trouble}
        <!-- 「messageKey」はja.json/en.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
        {@html trouble["message" + $_("config.nameSuffix")]
          ? escape(trouble["message" + $_("config.nameSuffix")]).replace(
              /\n/g,
              "<br />",
            )
          : $_(messageKey)}
      {:else if returnInfo}
        <!-- ja.json/en.jsonに定義されたメッセージしか表示されないためHTMLエスケープ不要 -->
        {@html $_(messageKey)}
      {:else}
        <!-- deliveryアプリでの操作で、「連絡方法」・「指示された置き配場所」で「その他」を選んだ場合はフリー記入のため、HTMLエスケープが必要 -->
        {@html escape(
          $_(messageKey, {
            values: {
              contactMethod: contactMethod,
              designatedDropPlace: designatedDropPlace,
            },
          }),
        ).replace(/\n/g, "<br />")}
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .troubleLine1 {
    display: flex;
  }
  .troubleMessage {
    width: 100%;
  }
  .formatedDate {
    margin-right: 0.2em;
  }
  @media screen and (min-width: 810px) {
    .trouble {
      display: flex;
      margin-top: 4px;
    }
    .troubleTime {
      min-width: max-content;
      padding-right: 15px;
    }
    .formatedDate {
      display: inline-block;
      text-align: right;
    }
    .formatedDate.ja {
      width: 4.2em;
    }
    .formatedDate.en {
      width: 3.7em;
    }
    .formatedTime {
      display: inline-block;
      text-align: right;
    }
    .formatedTime.ja {
      width: 2.5em;
    }
    .formatedTime.en {
      width: 4.3em;
    }
  }
  @media screen and (max-width: 809px) {
    .trouble {
      display: column;
      margin-top: 8px;
      line-height: initial;
    }
    .troubleTime {
      min-width: max-content;
      font-weight: bold;
    }
  }
</style>

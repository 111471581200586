<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { HTTPError } from "ky";
  import { _, locale } from "svelte-i18n";

  import backendApi from "~/libs/backendApi";
  import { DropOffLocation, DropOffLocationList } from "~/libs/commonTypes";
  import { formatDate, getJstDate } from "~/libs/dateUtils";
  import loadingProgress from "~/libs/loadingProgress";
  import { toast } from "~/libs/toast";

  /** @type {string} */
  export let trackingNumber;

  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /**
   * 再配達希望日時の表示を切り替える関数
   * @type {Function}
   */
  export let configureLocaleSpecificFormat;

  /**
   * 選択肢として表示する日数
   * @type {number}
   */
  const DATE_NUM = 6;

  /**
   * モーダルの開閉状況
   * @type {boolean}
   */
  let open;

  /**
   * 選択肢として表示する日時のリスト
   * @type {Array<Date>}
   */
  let choicesDateList = (() => {
    const now = new Date();
    const choicesDateList = [];
    for (let i = 0; i < DATE_NUM; i++) {
      let date = getJstDate(now);
      date.setDate(date.getDate() + i + 1);

      choicesDateList.push(date);
    }
    return choicesDateList;
  })();

  /**
   * 再配達の希望受け渡し方法
   * @type {number | null}
   */
  let desiredPackageDropPlace = Number.isInteger(result.packageDropPlace)
    ? result.packageDropPlace
    : null;

  /**
   * 再配達の希望日
   * @type {string}
   */
  let desiredDate = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
    : "";

  /**
   * 再配達の希望時間帯
   * @type {string}
   */
  let desiredTime = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : "";

  /**
   * 変更前の希望受け渡し方法
   *  @type {number | null}
   */
  let currentPackageDropPlace = Number.isInteger(result.packageDropPlace)
    ? desiredPackageDropPlace
    : null;

  /**
   * 変更前の再配達の希望日
   * @type {string}
   */
  let currentSpecifiedDate = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
    : "";

  /**
   * 変更前の再配達の希望時間帯
   * @type {string}
   */
  let currentSpecifiedTime = result.specifiedPickupDatetime
    ?.desiredRedeliveryDatetime
    ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
    : "";

  /**
   * 変更後の再配達の希望受け渡し方法
   * @type {number}
   */
  $: newPackageDropPlace = desiredPackageDropPlace;

  /**
   * 変更後の再配達の希望日
   * @type {string}
   */
  $: newSpecifiedDate = desiredDate;

  /**
   * 変更後の再配達の希望時間帯
   * @type {string}
   */
  $: newSpecifiedTime = desiredTime;

  /** @type {string} 荷受人郵便番号 */
  let postcode = sessionStorage.getItem("identificationPostcode") ?? "";
  /** @type {string} 荷受人電話番号 */
  let tel = sessionStorage.getItem("identificationTel") ?? "";

  /**
   * ダイアログを開く。
   */
  export function openDialog() {
    desiredPackageDropPlace = Number.isInteger(result.packageDropPlace)
      ? result.packageDropPlace
      : null;
    desiredDate = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
      : "";
    desiredTime = result.specifiedPickupDatetime?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : "";
    currentPackageDropPlace = Number.isInteger(result.packageDropPlace)
      ? desiredPackageDropPlace
      : null;
    currentSpecifiedDate = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.date
      : "";
    currentSpecifiedTime = result.specifiedPickupDatetime
      ?.desiredRedeliveryDatetime
      ? result.specifiedPickupDatetime.desiredRedeliveryDatetime.timeFrame
      : "";

    open = true;
  }
  function closeDialog() {
    desiredPackageDropPlace = null;
    desiredDate = "";
    desiredTime = "";
    open = false;
  }

  /**
   * 配送希望時間をフォーマットする
   * @param {string} timeFrame 数字4桁（開始時間2桁、終了時間2桁）
   * @param {import("svelte-i18n").locale} locale svelte-i18nのlocale
   * @returns {string} 時間指定の表示
   */
  function formatTimeFrame(timeFrame, locale) {
    let startTime = Number(timeFrame.substring(0, 2));
    let endTime = Number(timeFrame.substring(2, 4));

    let str = "";

    if (locale === "ja") {
      str = startTime + "時～" + endTime + "時";
    } else {
      let startTimeZone = "AM";
      let endTimeZone = "AM";

      if (startTime > 12) {
        startTime = startTime - 12;
        startTimeZone = "PM";
      }
      if (endTime > 12) {
        endTime = endTime - 12;
        endTimeZone = "PM";
      }

      str =
        startTime +
        ":00 " +
        startTimeZone +
        " - " +
        endTime +
        ":00 " +
        endTimeZone;
    }

    return str;
  }

  /**
   * 受け渡し方法・日時を更新する
   */
  function updateDesiredDateTime() {
    this.setAttribute("data-mdc-dialog-action", "close");

    /** @type {import("~/libs/commonTypes").DateAndTimeFrame} */
    const newDesiredDateAndTimeFrame = {
      date: newSpecifiedDate !== currentSpecifiedDate ? newSpecifiedDate : "",
      timeFrame:
        newSpecifiedTime !== currentSpecifiedTime ? newSpecifiedTime : "",
    };
    /** @type {import("~/libs/commonTypes").SpecifiedPickupDatetime} */
    const newSpecifiedPickupDatetime = {
      desiredRedeliveryDatetime: newDesiredDateAndTimeFrame,
      availablePickupDatetime: [],
    };
    /** @type {import("~/libs/backendApi").ReceiverIdentification} */
    const receiverIdentification =
      postcode && tel
        ? {
            postcode,
            tel,
          }
        : null;
    loadingProgress.wrapAsync(async () => {
      try {
        await backendApi.updateDesiredRedeliveryDatetime(
          trackingNumber,
          newPackageDropPlace,
          newDesiredDateAndTimeFrame,
          receiverIdentification,
        );
        result.packageDropPlace = newPackageDropPlace;
        if (newSpecifiedPickupDatetime.desiredRedeliveryDatetime.date) {
          result.specifiedPickupDatetime = newSpecifiedPickupDatetime;
          delete result.redeliveryContext?.adjustedRedeliveryDatetime;
        }
        configureLocaleSpecificFormat();
        toast.info(
          $_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.completion"),
        );
        closeDialog();
      } catch (error) {
        showErrorToast(error);
      }
    })();
  }

  /**
   * エラーメッセージをトーストで表示する。
   * @param {Error} error Errorオブジェクト
   */
  function showErrorToast(error) {
    if (error["errorResponse"]?.title === "delivery completed.") {
      toast.error($_("errors.deliveredPackage"));
    } else if (
      error instanceof HTTPError &&
      error.response?.status >= 400 &&
      error.response?.status < 500
    ) {
      console.error(error);
      toast.error($_("errors.invalidDesiredRedeliveryDatetime"));
    } else {
      console.error(error);
      toast.error($_("errors.failedDesiredRedeliveryDatetime"));
    }
  }
</script>

<div class="setDesiredRedeliveryDateDialog">
  <Dialog
    bind:open
    scrimClickAction=""
    escapeKeyAction=""
    aria-labelledby="set-desired-redelivery-datetime-dialog-title"
    aria-describedby="set-desired-redelivery-datetime-dialog-content"
    style="margin-top: 50px; max-height: 90%"
  >
    <Title id="set-desired-redelivery-datetime-dialog-title"
      >{$_("pages.Tracking.SetDesiredRedeliveryDatetimeDialog.title")}</Title
    >
    <Content id="set-desired-redelivery-datetime-dialog-content">
      <div class="selectArea">
        <div class="desiredPackageDropPlace">
          <p class="title">
            {$_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.desiredPackageDropPlaceLabel",
            )}
            <span class="required"
              >{$_(
                "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.requiredLabel",
              )}</span
            >
          </p>
          <label class="packageDropPlaceLabel">
            <select
              name="packageDropPlace"
              class="selectInput"
              id="packageDropPlace"
              bind:value={desiredPackageDropPlace}
            >
              <option value={null} selected disabled
                >{$_(
                  "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notSelectedLabel",
                )}</option
              >
              {#each DropOffLocationList as dropplace}
                <option value={dropplace}>
                  {$_(`messages.packageDropPlace.${dropplace}`)}
                </option>
              {/each}
            </select>
          </label>
        </div>
        <div class="desiredDate">
          <p class="title">
            {$_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.desiredDateLabel",
            )}
            {#if newPackageDropPlace === DropOffLocation.DELIVERY_IN_PERSON}
              <span class="required"
                >{$_(
                  "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.requiredLabel",
                )}</span
              >
            {/if}
          </p>
          <div class="tableWrapper">
            {#each choicesDateList as dateAndTimeFrame}
              <label class="date">
                <input
                  type="radio"
                  name="desiredDate"
                  bind:group={desiredDate}
                  value={formatDate(dateAndTimeFrame, "yyyy-MM-dd", $locale)}
                />
                <div class="dateInner">
                  {formatDate(
                    dateAndTimeFrame,
                    $_("config.defaultDateFormat"),
                    $locale,
                  )}
                </div>
              </label>
            {/each}
          </div>
        </div>
        <div class="desiredTimeFrame">
          <p class="title">
            {$_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.desiredTimeLabel",
            )}
            {#if newPackageDropPlace === DropOffLocation.DELIVERY_IN_PERSON}
              <span class="required"
                >{$_(
                  "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.requiredLabel",
                )}</span
              >
            {/if}
          </p>
          <div class="tableWrapper">
            {#each result.redeliveryContext?.timeFramePreset as timeFrame}
              <label class="timeFrame">
                <input
                  type="radio"
                  name="desiredTimeFrame"
                  bind:group={desiredTime}
                  value={timeFrame}
                />
                <div class="timeFrameInner">
                  {formatTimeFrame(timeFrame, $locale)}
                </div>
              </label>
            {/each}
          </div>
        </div>
      </div>
      <div class="note">
        <span class="material-icons md-24">info</span>
        <div class="noteText">
          <div class="noteText_main">
            <p class="caption">
              {@html $_(
                "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notAvailableAllTimeFrameCaption",
              )}
            </p>
            {@html $_(
              "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.notAvailableAllTimeFrameNote",
            )}
          </div>
          <div class="noteText_contact">
            <span>{$_("pages.Main.contactInfoLabel")}</span>
            <span class="tel">
              <span class="material-icons md-22">call</span>
              <a href="tel:080-7140-4491">{$_("pages.Main.contactInfoTel")}</a>
            </span>
            <span class="reception"
              >({$_("pages.Main.contactInfoReceptionLabel")}
              {$_("pages.Main.contactInfoReceptionTime")})</span
            >
          </div>
        </div>
      </div>
    </Content>
    <Actions>
      <Button on:click={closeDialog}>
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.cancelButtonLabel",
          )}</Label
        >
      </Button>
      <!-- 受け渡し方法が選択されていない or 手渡しで日時の選択なし or 日付か時間のどちらかが選択なし or 値が変更されていない 場合に非活性 -->
      <Button
        on:click={updateDesiredDateTime}
        disabled={!Number.isInteger(newPackageDropPlace) ||
          (newPackageDropPlace === DropOffLocation.DELIVERY_IN_PERSON &&
            (!newSpecifiedDate || !newSpecifiedTime)) ||
          (newSpecifiedDate && !newSpecifiedTime) ||
          (!newSpecifiedDate && newSpecifiedTime) ||
          (currentPackageDropPlace === newPackageDropPlace &&
            currentSpecifiedDate === newSpecifiedDate &&
            currentSpecifiedTime === newSpecifiedTime)}
      >
      
        <Label
          >{$_(
            "pages.Tracking.SetDesiredRedeliveryDatetimeDialog.updateButtonLabel",
          )}</Label
        >
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  p {
    font-size: 15px;
    color: #000;
  }
  .selectArea {
    width: 99%;
    margin: 0 auto 10px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 12px;
    margin-bottom: 6px;
    font-weight: bold;
    line-height: normal;
    span {
      font-size: 12px;
      color: #672b2a;
      background-color: #ffe7e7;
      padding: 1.4px 6px 2px;
      margin-left: 2px;
      border-radius: 2px;
      vertical-align: middle;
    }
  }
  .tableWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 2px;
  }
  .date,
  .timeFrame {
    flex: 1 1 45%;
  }
  .desiredPackageDropPlace {
    :global(.mdc-select--outlined .mdc-select__anchor) {
      height: auto;
    }
    .packageDropPlaceLabel {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 100%;
      &::after {
        position: absolute;
        right: 19px;
        width: 10px;
        height: 7px;
        background-color: #666;
        clip-path: polygon(0 0, 100% 0, 50% 76%);
        content: "";
        pointer-events: none;
      }
      select {
        appearance: none;
        width: 100%;
        height: 2em;
        padding: 0.4em 30px 0.4em 0.8em;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: #fff;
        color: #333333;
        font-size: 1em;
        cursor: pointer;
      }
      select:hover {
        border-color: #333;
      }
    }
    .selectInput {
      height: 40px;
    }
  }
  .desiredDate .date input[type="radio"],
  .desiredTimeFrame .timeFrame input[type="radio"] {
    position: absolute;
    left: -1000%;
  }
  .desiredDate .date .dateInner,
  .desiredTimeFrame .timeFrame .timeFrameInner {
    padding: 4px 0;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  .desiredDate .date input[type="radio"] + .dateInner,
  .desiredTimeFrame .timeFrame input[type="radio"] + .timeFrameInner {
    background-color: #fff;
  }
  .desiredDate .date input[type="radio"]:checked + .dateInner,
  .desiredTimeFrame .timeFrame input[type="radio"]:checked + .timeFrameInner {
    color: #fff;
    background-color: #018786;
    border-color: #018786;
  }
  .note {
    box-sizing: border-box;
    display: flex;
    margin-top: 10px;
    align-items: center;
    background-color: #f5f5f5;
    color: #242424;
    border-radius: 5px;
    max-width: 700px;
    min-width: 232px;

    > .material-icons {
      color: #018786;
      margin: 0 10px;
    }

    .noteText {
      .noteText_contact {
        border-top: 1px solid #979797;
        display: flex;

        .tel {
          display: flex;
          align-items: center;

          .material-icons {
            margin-right: 3px;
          }
          .material-icons.md-22 {
            font-size: 22px;
          }
          a {
            color: #242424;
          }
        }
      }
    }
  }

  @media screen and (min-width: 810px) {
    .setDesiredRedeliveryDateDialog {
      :global(.mdc-dialog .mdc-dialog__surface) {
        max-width: 800px;
      }
    }
    .note {
      margin-top: 20px;
      padding: 10px 15px 10px 3px;

      .noteText {
        font-size: 14px;

        .noteText_main {
          padding-left: 10px;

          .caption {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 2px;
          }
        }
        .noteText_contact {
          align-items: center;
          margin-top: 5px;
          padding-top: 5px;
          padding-left: 10px;

          .tel {
            margin-left: 10px;
          }
          .reception {
            margin-left: 8px;
          }
        }
      }
      a {
        cursor: default;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 809px) {
    .setDesiredRedeliveryDateDialog {
      :global(.mdc-dialog__title) {
        padding: 0 15px;
      }
      :global(.mdc-dialog__content) {
        padding: 10px 15px;
      }
    }
    .desiredDate .date .dateInner,
    .desiredTimeFrame .timeFrame .timeFrameInner {
      font-size: smaller;
    }
    .note {
      padding: 10px 15px;

      > .material-icons {
        display: none;
      }

      .noteText {
        .noteText_main {
          font-size: 13px;
          line-height: normal;
          .caption {
            font-size: 14px;
            font-weight: 550;
            margin-bottom: 2px;
          }
        }
        .noteText_contact {
          font-size: 13px;
          flex-flow: column;
          margin-top: 6px;
          padding-top: 6px;
        }
      }
    }
  }
</style>

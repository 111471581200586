<script>
  import Button, { Label } from "@smui/button";
  import Dialog, { Actions, Content, Title } from "@smui/dialog";
  import { _ } from "svelte-i18n";

  /** @type {boolean} */
  export let open;
  /** @type {import("~/libs/commonTypes").TrackingResult} */
  export let result;

  /** @type {Array<string>} 宅配ボックス番号リスト */
  let deliveryBoxNumberList;
  /** @type {Array<string>} 宅配ボックス暗証番号リスト */
  let deliveryBoxPinList;

  $: if (result?.deliveryBoxNumber || result?.deliveryBoxPin) {
    deliveryBoxNumberList = result.deliveryBoxNumber?.split("\n");
    deliveryBoxPinList = result.deliveryBoxPin?.split("\n");

    if (
      deliveryBoxNumberList === undefined ||
      deliveryBoxNumberList.length === 0
    ) {
      deliveryBoxNumberList = new Array(deliveryBoxPinList.length).fill("");
    }
    if (deliveryBoxPinList === undefined || deliveryBoxPinList.length === 0) {
      deliveryBoxPinList = new Array(deliveryBoxNumberList.length).fill("");
    }
  }
</script>

<div class="additionalInfoDialog">
  <Dialog
    bind:open
    aria-labelledby="additional-info-dialog-title"
    aria-describedby="additional-info-dialog-content"
  >
    <Title id="additional-info-dialog-title"
      >{$_("pages.Tracking.AdditionalInfoDialog.title")}</Title
    >
    <Content id="additional-info-dialog-content">
      {#if result.podPhotoUrl}
        <img class="photo" src={result.podPhotoUrl} alt="locationImage" />
      {/if}
      {#if deliveryBoxNumberList?.length > 0 || deliveryBoxPinList?.length > 0}
        <table>
          <tr>
            <th>{$_("pages.Tracking.AdditionalInfoDialog.boxNumber")}</th>
            <th>{$_("pages.Tracking.AdditionalInfoDialog.boxPin")}</th>
          </tr>
          {#each deliveryBoxNumberList as boxNumber, i}
            <tr>
              <td>{boxNumber || "-"}</td>
              <td>{deliveryBoxPinList[i] || "-"}</td>
            </tr>
          {/each}
        </table>
      {/if}
    </Content>
    <Actions>
      <Button on:click={() => (open = false)}>
        <Label
          >{$_("pages.Tracking.AdditionalInfoDialog.closeButtonLabel")}</Label
        >
      </Button>
    </Actions>
  </Dialog>
</div>

<style lang="scss">
  .additionalInfoDialog {
    :global(.mdc-dialog__container) {
      height: 80%;
    }
    :global(.mdc-dialog .mdc-dialog__surface) {
      max-height: none;
    }
  }
  .photo {
    display: block;
    margin: 0 auto;
    max-height: calc(80vh - 200px);
  }
  table {
    min-width: 250px;
    width: 90%;
    margin: 10px auto;
  }
  th,
  td {
    width: 45%;
    padding: 6px 6px 6px 12px;
    text-align: center;
    border: 1px solid #999;
  }
  th {
    background-color: #f5f5f5;
  }

  @media screen and (min-width: 810px) {
    .photo {
      max-width: 400px;
    }
  }
  @media screen and (max-width: 809px) {
    .photo {
      max-width: 280px;
    }
  }
</style>
